import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {isValidCpf, MustMatch, upperCaseFirstLetter} from '../../helpers';
import {StatusMessage} from '../../interfaces/status-message';
import {Router} from '@angular/router';
import {KokarService} from '../../services/kokar.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recovery-password.component.html',
    styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {

    recoveryPasswordForm: FormGroup;
    submitted: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private kokarService: KokarService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {

        this.recoveryPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    get f() {
        return this.recoveryPasswordForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // console.log('onSubmit Recovery Password', this.recoveryPasswordForm.value);

        if(this.recoveryPasswordForm.invalid) {
            return;
        }

        this.kokarService.sendEmail(this.recoveryPasswordForm.value).then((result: StatusMessage) => {
            // console.log('SEND EMAIL Recovey Password result', result);
            this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
        }).catch((error) => {
            // console.log('SEND EMAIL Recovey Password error', error);
            this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
        });

    }

}
