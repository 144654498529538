import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {NewPasswordComponent} from './pages/new-password/new-password.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {FormUserComponent} from './pages/form-user/form-user.component';
import {RecoveryPasswordComponent} from './pages/recover-password/recovery-password.component';
import {FormOkaComponent} from './pages/form-oka/form-oka.component';
import {AuthGuard} from './helpers';
import {ProfileComponent} from './pages/profile/profile.component';
import {OkaComponent} from './pages/oka/oka.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {AuthenticationService} from './services';

const routes: Routes = [

    // Formulário para novo usuário
    {path: 'new-user', component: FormUserComponent},
    // Formulário para usuário existente
    {path: 'form/profile/:id', component: FormUserComponent, canActivate: [AuthGuard]},
    // View do usuário ou quando entra na rota root com um token válido
    {path: 'profile/:id', component: ProfileComponent, canActivate: [AuthGuard]},

    // Formulário para oka existente
    {path: 'form/oka/:id', component: FormOkaComponent, canActivate: [AuthGuard]},
    // Formulário de Nova Oka
    {path: 'form/new-oka', component: FormOkaComponent, canActivate: [AuthGuard]},
    // View da Oka
    {path: 'oka/:id', component: OkaComponent, canActivate: [AuthGuard]},

    // Formulário de Login
    {path: 'login', component: LoginComponent},

    // Formulário para nova senha
    {path: 'new-password', component: NewPasswordComponent},
    // Formulário para recuperar a senha (E-mail)
    {path: 'recovery-password', component: RecoveryPasswordComponent},

    // Caso entre na rota root sem um token válido
    {path: '', redirectTo: '/login', pathMatch: 'full'},

    // Formulário de Login
    {path: 'logout', component: LogoutComponent},

    // Caso entre em uma rota inexistente
    {path: '**', component: NotFoundComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
