import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Profile} from '../interfaces/profile';
import {environment} from '../../environments/environment';
import {Address} from '../interfaces/address';
import {StatusMessage} from '../interfaces/status-message';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    async insert(data: any): Promise<StatusMessage> {

        return new Promise<StatusMessage>(async (resolve, reject) => {

            const address: Address = {
                cep: data.cep,
                logradouro: data.logradouro,
                numero: data.numero,
                bairro: data.bairro,
                localidade: data.localidade,
                uf: data.uf,
                complemento: data.complemento,
                descricao: 'Principal',
                main: 1
            };

            const user: Profile = {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                cpf: data.cpf,
                cel: data.cel,
                password: data.password,
                gender: data.gender,
                birth: data.birth,
                address: address,
                role: 'USER'
            };

            await this.http.post(`${environment.API_URL}/user/create`, {...user}).subscribe(
                (resultData: StatusMessage) => resolve(resultData),
                (error: any) => reject({status: 'Erro', message: error}));
        });
    }

    async update(user: any, updateUser: any): Promise<StatusMessage> {
        return new Promise<StatusMessage>((async (resolve, reject) => {

            let keys = Object.keys(updateUser);
            delete updateUser.acceptTerms;

            let changed: any = {};
            let changePassword = {};
            let changeAddress = {};
            keys.forEach((key) => {
                if(user[key] !== updateUser[key]) {
                    if(updateUser[key] !== '' && (key === 'password' || key === 'confirmPassword' || key === 'oldPassword')) {
                    changePassword = Object.assign({}, changePassword, {[key]: updateUser[key]});
                    } else if(key === 'cep' || key === 'logradouro' || key === 'numero' || key === 'bairro' || key === 'localidade' || key === 'uf' || key === 'complemento' || key === 'descricao') {
                    changeAddress = Object.assign({}, changeAddress, {[key]: updateUser[key]});
                    } else {
                    changed = Object.assign({}, changed, {[key]: updateUser[key]});
                    }
                }
            });

            delete changed.password;
            delete changed.confirmPassword;
            delete changed.oldPassword;

            // console.log('CHANGED', changed);
            // console.log('PASSWORD', changePassword);
            // console.log('ADDRESS', changeAddress);

            if((Object.keys(changed).length > 0) || (Object.keys(changePassword).length > 0) || (Object.keys(changeAddress).length > 0)) {
                let request = changed;
                console.log(request);
                if (Object.keys(changeAddress).length > 0) {
                    request = Object.assign({}, request, {address: changeAddress});
                }

                if (Object.keys(changePassword).length > 0) {
                    request = Object.assign({}, request, changePassword);
                }

                await this.http.post(`${environment.API_URL}/user/${user.id}`, request).subscribe(
                    (resultData: StatusMessage) => resolve(resultData),
                    (error: any) => reject({status: 'Erro', message: error}));
            }

        }));
    }

    getAddressList(id: number) {
        return new Promise<Address[] | object>(async (resolve, reject) => {
            await this.http.get(`${environment.API_URL}/user/address/${id}`).subscribe(
                (data: { status: string, address: Address[] }) => {

                    resolve(data.address);
                },
                (error: any) => reject({status: 'Erro', message: error}));
        });
    }

    getProfile(id: number) {

        // console.log('GET PROFILE SERVICE', typeof id, id);

        id = typeof id === 'string' ? parseInt(id) : id;

        return new Promise<Profile>(async (resolve, reject) => {
            await this.http.get(`${environment.API_URL}/user/${id}`).subscribe(
                (data: any) => {
                    // console.log('DATA', data.user);
                    resolve(data.user);
                },
                (error: any) => {
                    // console.log('ERROR', error);
                    reject({status: 'Erro', message: error});
                });
        });
    }

    getAll() {

        return this.http.get<Profile[]>(`${environment.API_URL}/users`);

    }
}
