import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './pages/login/login.component';
import {NewPasswordComponent} from './pages/new-password/new-password.component';
import {OkaComponent} from './pages/oka/oka.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {FormUserComponent} from './pages/form-user/form-user.component';
import {RecoveryPasswordComponent} from './pages/recover-password/recovery-password.component';
import {FormOkaComponent} from './pages/form-oka/form-oka.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {NgxViacepModule} from '@brunoc/ngx-viacep';
import {DatePipe} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor, JwtInterceptor} from './helpers';
import {MenuComponent} from './components/menu/menu.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {DebugComponent} from './components/debug/debug.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {TermsComponent} from './components/terms/terms.component';
import {ButtonReturnComponent} from './components/button-return/button-return.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SimpleModalModule} from 'ngx-simple-modal';
import {ModalComponent} from './components/modal/modal.component';
import {HintModule} from 'angular-custom-tour';


export let optionsMask: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        NewPasswordComponent,
        OkaComponent,
        NotFoundComponent,
        FormUserComponent,
        RecoveryPasswordComponent,
        FormOkaComponent,
        InputTextComponent,
        MenuComponent,
        ProfileComponent,
        NavbarComponent,
        DebugComponent,
        LogoutComponent,
        TermsComponent,
        ButtonReturnComponent,
        BackButtonComponent,
        ModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgxViacepModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        SimpleModalModule,
        NgxMaskModule.forRoot(optionsMask),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        FormsModule,
        HintModule
    ],
    entryComponents: [
        ModalComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [
        DatePipe,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
