import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {StatusMessage} from '../interfaces/status-message';
import {Address} from '../interfaces/address';
import {Profile} from '../interfaces/profile';
import {environment} from '../../environments/environment';
import {Oka} from '../interfaces/oka';

@Injectable({
    providedIn: 'root'
})
export class OkaService {

    constructor(private http: HttpClient) {
    }

    async insert(data: any): Promise<StatusMessage> {

        return new Promise<StatusMessage>(async (resolve, reject) => {

            const address: Address = {
                cep: data.cep,
                logradouro: data.logradouro,
                numero: data.numero,
                bairro: data.bairro,
                localidade: data.localidade,
                uf: data.uf,
                complemento: data.complemento,
                descricao: data.descricao,
            };

            const oka: Oka = {
                serial: data.serial,
                access_code: data.access_code,
                name: data.name,
                address: address,
                role: 'Proprietário'
            };

            await this.http.post(`${environment.API_URL}/oka`, {...oka, id_user: data.id_user}).subscribe(
                (resultData: StatusMessage) => resolve(resultData),
                (error: any) => reject({status: 'Erro', message: error}));
        });
    }

    async update(oldOka, newOka): Promise<StatusMessage> {
        return new Promise<StatusMessage>(async (resolve, reject) => {

            delete oldOka.serial;
            delete oldOka.access_code;
            delete oldOka.id_address;
            delete oldOka.principal_address;
            delete newOka.addressSelect;

            oldOka = {
                id_oka: oldOka.id.toString(),
                name: oldOka.name,
                cep: oldOka.cep,
                logradouro: oldOka.logradouro,
                numero: oldOka.numero,
                bairro: oldOka.bairro,
                localidade: oldOka.localidade,
                complemento: oldOka.complemento,
                uf: oldOka.uf,
                descricao: oldOka.descricao_address
            };

            let keys = Object.keys(newOka);
            let changed: any = {};
            let changeAddress = {};
            keys.forEach((key) => {
                if(oldOka[key] !== newOka[key]) {
                    if(key === 'cep' || key === 'logradouro' || key === 'numero' || key === 'bairro' || key === 'localidade' || key === 'uf' || key === 'complemento' || key === 'descricao') {
                        changeAddress = Object.assign({}, changeAddress, {[key]: newOka[key]});
                    } else {
                        changed = Object.assign({}, changed, {[key]: newOka[key]});
                    }
                }
            });

            if(Object.keys(changed).length > 0) {
                let request = changed;

                if(Object.keys(changeAddress).length > 0) {
                    request = Object.assign({}, request, {address: changeAddress});
                }


                // console.log('REQUEST', request);
                await this.http.patch(`${environment.API_URL}/oka/${oldOka.id_oka}`, request).subscribe(
                    (resultData: StatusMessage) => resolve(resultData),
                    (error: any) => reject({status: 'Erro', message: error}));
            } else {
                resolve({status: 'sucesso', message: 'Sua Oka não foi atualizada pois nenhum campo foi alterado!'});
            }

            resolve({status: 'sucesso', message: 'OK'});
        });
    }

    getOka(id: number, address: boolean = false, sync_google: boolean = false, sync_alexa: boolean = false) {
        return new Promise<Oka>(async (resolve, reject) => {
            let params: HttpParams = new HttpParams();

            if(address) {
                params = params.append('address', address.toString());
            }

            if(sync_google) {
                params = params.append('sync_google', sync_google.toString());
            }

            if(sync_alexa) {
                params = params.append('sync_alexa', sync_alexa.toString());
            }

            await this.http.get(`${environment.API_URL}/oka/${id}`, {params: params}).subscribe(
                (data: { status: string, oka: Oka }) => {

                    resolve(data.oka);
                },
                (error: any) => reject({status: 'Erro', message: error}));
        });
    }

    validSerialAndAccessCode(serial, access_code): Promise<boolean> {

        return new Promise<boolean>(async (resolve, reject) => {
            this.http.post<boolean>(`${environment.API_URL}/valid`, {
                serial: serial,
                access_code: access_code
            }).subscribe(() => {
                resolve(true);
            }, () => {
                reject(false);
            });
        });
    }

    syncUserOka(params: { user: Profile, oka: Oka, assistant: string, email?: string | null }) {
        return new Promise<StatusMessage>((resolve, reject) => {

            let userParams = params.assistant === 'google_home' ? {
                    name: `${params.user.firstname} ${params.user.lastname}`,
                    email: params.email !== null ? params.email : params.user.email,
                    picture: '',
                    id_google: 'not sync by google',
                    tester: 1,
                } : {
                    name: `${params.user.firstname} ${params.user.lastname}`,
                    email: params.email !== null ? params.email : params.user.email,
                };

            this.http.post<StatusMessage>(`${environment.API_URL}/syncAssistant`, {
                user: userParams,
                oka: {
                    id: params.oka.id,
                    serial: params.oka.serial,
                    access_code: params.oka.access_code
                },
                assistant: params.assistant
            }).subscribe((result) => {
                // resolve({status: 'sucesso', message: 'Oka Sincronizada com sucesso!'});
                resolve(result);
            }, (err) => {
                // reject({status: 'erro', message: 'Erro ao tentar sincronizar sua Oka com o seu Assistente!'});
                reject(err);
            });
        });
    }

    deleteOka(params: {user: Profile, oka: Oka}){
        let data = params;
        return new Promise<StatusMessage>((resolve, reject) => {
            // @ts-ignore
            this.http.delete<StatusMessage>(`${environment.API_URL}/deleteSync/deleteOka`,{ params: {data: JSON.stringify(data)}}).subscribe((result) => {
            // @ts-ignore
                resolve(result);
            }, (err => {
                reject(err);
            }))
        });
    }
}
