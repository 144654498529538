import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StatusMessage} from '../../interfaces/status-message';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services';
import {first} from 'rxjs/operators';
import {upperCaseFirstLetter} from '../../helpers';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    submitted: boolean = false;
    result: StatusMessage;
    loading: boolean = false;
    returnUrl: string;
    error: any = '';

    constructor(private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService) {

        /*if(this.authenticationService.currentUserValue) {
            this.router.navigate([`/profile/${this.authenticationService.currentUserValue.data.id}`]);
        }*/
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });

        this.returnUrl = '/';
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {

        this.submitted = true;

        if(this.loginForm.invalid) {
            let keys = Object.keys(this.loginForm.value);
            keys.forEach((key) => {

                if(this.loginForm.controls[key].errors.required) {
                    this.toastr.error(`Campo ${key} necessário!`, 'Erro'
                    );
                }

                if(this.loginForm.controls[key].errors.email) {
                    this.toastr.error(`Digite um e-mail válido!`, 'Erro');
                }
            });
            return;
        }

        this.loading = true;
        this.access();
    }

    access() {

        /*this.authenticationService.login(this.loginForm.value).then(result => {
            console.log('LOGIN', result);
            this.toastr.success(`${result.message}`, upperCaseFirstLetter(result.status));
            this.router.navigate([`/profile/${result.data.id}`]);
        }).catch(err => {
            console.log('LOGIN ERROR', err);
            this.toastr.error(`${err.message}`, upperCaseFirstLetter(err.status));
        });*/
        this.authenticationService.login(this.loginForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    // console.log('DATA ACCESS LOGIN', data);
                    this.toastr.success(data.message, upperCaseFirstLetter(data.status));
                    this.router.navigate([`/profile/${data.id}`]);
                },
                error => {
                    this.toastr.error(error, 'Erro');
                    this.error = error;
                    this.loading = false;
                });
    }

}
