import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OkaService} from '../../services';
import {Oka} from '../../interfaces/oka';
import {ToastrService} from 'ngx-toastr';
import {upperCaseFirstLetter} from '../../helpers';
import {SimpleModalService} from 'ngx-simple-modal';
import {ModalComponent} from '../../components/modal/modal.component';
import {Address} from '../../interfaces/address';
import {Profile} from '../../interfaces/profile';
import {StatusMessage} from '../../interfaces/status-message';

@Component({
    selector: 'app-oka',
    templateUrl: './oka.component.html',
    styleUrls: ['./oka.component.css']
})
export class OkaComponent implements OnInit {

    user: Profile = {};
    id_oka: number = 0;
    oka: Oka = {};
    addressString: string = 'Edite a Oka para adicionar o endereço!';
    backLink: string = '/';
    statusSync: {
        alexa?: boolean,
        google?: boolean,
    } = {alexa: false, google: false};
    navbarOpen = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private okaService: OkaService,
                private toastr: ToastrService,
                private modalService: SimpleModalService) {
    }

    async ngOnInit() {
        await this.getUser(this.route.snapshot.queryParams.user);
    }

    async getUser(user) {
        user = JSON.parse(user);
        let oka = user.okas[0];
        this.oka = oka;
        this.user = user;

        // console.log('USER', this.user);
        // console.log('OKA', this.oka);

        this.id_oka = oka.id;

        if(oka.address !== null) {
            this.addressString = `${oka.address.logradouro}, ${oka.address.numero}`;
        }

        if(oka.sync_google.length > 0) {
            if (oka.sync_google[0].email === this.user.email) {
                this.statusSync.google = true;
            }
        }

        if(oka.sync_alexa.length > 0) {
            if (oka.sync_alexa[0].email === this.user.email) {
                this.statusSync.alexa = true;
            }
        }

        this.backLink = `/profile/${user.id}`;
    }

    async openModal(assistant: string, data: any = null) {

        let emailSelected: object[] = data.filter((item) => {
            if(item.email === this.user.email) {
                item.selected = true;
                return item;
            } else {
                item.selected = false;
            }
        });

        let assistantName = 'Alexa';
        let assistantDescription = `<span class='atencaoTexto'>ATENÇÃO!</span> <br>Nesse ponto é <b class='textoBold'>importante</b> que você já tenha seu dispositivo Echo Dot configurado <b class='textoBold'>E</b> a Skill da Kokar adicionada no APLICATIVO DA ALEXA! <a class='obsText' target='_self' href='https://kokar.com/alexa/'>(verifique os passos em nosso site)</a> <br><br> Após feito os passos acima, ADICIONE ABAIXO o <b class='textoBold'>mesmo email </b>que foi utilizado na Skill dentro da Alexa.<br>`;
        
        if(assistant === 'google_home') {
            assistantName = 'Google Home';
            assistantDescription = `<span class='atencaoTexto'>ATENÇÃO!</span> <br>Nesse ponto é <b class='textoBold'>importante</b> que você já tenha seu dispositivo Google Home configurado <b class='textoBold'>E</b> a Skill da Kokar adicionada no APLICATIVO DO GOOGLE HOME! <a class='obsText' target='_self' href='https://kokar.com/google-home/'>(verifique os passos em nosso site)</a> <br><br> Após feito os passos acima, ADICIONE ABAIXO o <b class='textoBold'>mesmo email </b>que foi utilizado na Skill dentro do Google Home.<br>`;
        }

        if(emailSelected.length === 0) {
            emailSelected = [{
                id: this.user.id,
                name: `${this.user.firstname} ${this.user.lastname}`,
                email: this.user.email,
                selected: false
            }];
        }

        this.modalService.addModal(ModalComponent, {
            title: assistantName,
            description: assistantDescription,
            question: 'E-mail: ',
            actual_email: this.user.email,
            emails: [...emailSelected, {id: 'other', email: 'Outro', selected: false}]
        }).subscribe((email) => {

            if (email) {
                let isSameUser = this.user.email === email ? null : email;

                this.okaService.syncUserOka({
                    user: this.user,
                    oka: this.oka,
                    assistant: assistant,
                    email: isSameUser
                }).then((result: StatusMessage) => {
                    this.toastr.success(`${result.message}`, upperCaseFirstLetter(result.status));
                    if(assistant === 'google_home') {
                        this.statusSync.google = true;
                    } else if(assistant === 'alexa') {
                        this.statusSync.alexa = true;
                    }
                }).catch((err) => {
                    this.toastr.error(`${err.message}`, upperCaseFirstLetter(err.status));
                });
            }
        });
    }

    excluirOka(){
        this.okaService.deleteOka({
            user: this.user,
            oka: this.oka
        });
        // this.backLink = `/profile/${this.user.id}`;
        this.router.navigate([`/profile/${this.user.id}`]);
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

}
