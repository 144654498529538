import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../helpers';
import {isValidCpf} from '../../helpers';
import {Endereco, ErroCep, NgxViacepService} from '@brunoc/ngx-viacep';
import {Profile} from '../../interfaces/profile';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services';
import {StatusMessage} from '../../interfaces/status-message';
import {upperCaseFirstLetter} from '../../helpers';

@Component({
    selector: 'app-form-user',
    templateUrl: './form-user.component.html',
    styleUrls: ['./form-user.component.css']
})
export class FormUserComponent implements OnInit {

    navbarOpen = false;

    menuHidden: boolean = false;
    profileForm: FormGroup;
    submitted: boolean = false;
    hasProfileData: boolean = false;
    user: Profile;
    id_user: number = 0;
    backLink: string = `/profile/`;
    userUpdate: {} = {};
    updateUser: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private viacep: NgxViacepService,
        private datePipe: DatePipe,
        private toastrService: ToastrService,
        private userService: UserService) {
    }

    async ngOnInit() {

        this.profileForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            cpf: ['', [isValidCpf(), Validators.required, Validators.maxLength(14)]],
            gender: ['Other', Validators.required],
            birth: ['', Validators.required],
            cel: [''],
            email: ['', [Validators.required, Validators.email]],
            cep: ['', Validators.required],
            logradouro: ['', Validators.required],
            numero: [''],
            bairro: ['', Validators.required],
            localidade: ['', Validators.required],
            uf: ['', Validators.required],
            complemento: [''],
            descricao: ['Principal'],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
            acceptTerms: [false, Validators.requiredTrue]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });

        // console.log('this.route.snapshot.params', this.route.snapshot.params.id);

        if(this.route.snapshot.url[0].path === 'new-user') {

            this.menuHidden = true;
            this.backLink = `/`;

        } else if(this.route.snapshot.params.hasOwnProperty('id')) {

            this.hasProfileData = true;

            const id: number = typeof this.route.snapshot.params.id === 'string' ? parseInt(this.route.snapshot.params.id) : this.route.snapshot.params.id;

            this.id_user = id;
            this.backLink = `/profile/${id}`;
            this.profileForm.addControl('id_address', new FormControl(''));
            this.profileForm.addControl('oldPassword', new FormControl('', Validators.required));

            // console.log('id', typeof id, id);

            await this.userService.getProfile(id).then((user: Profile) => {

                // console.log('USER FORM ', user);
                const userForm = user;

                this.user = user;
                let birth = {birth: this.datePipe.transform(userForm.birth, 'yyyy-MM-dd')};

                const addressUser = user.address;

                delete userForm.address;

                // this.profileForm.addControl('odPassword', new FormControl('', Validators.required));
                // // console.log(addressUser);
                this.menuHidden = false;
                this.updateUser = true;
                this.userUpdate = {...userForm, ...addressUser, ...birth, id_address: addressUser.id};
                this.profileForm.patchValue({...userForm, ...addressUser, ...birth, id_address: addressUser.id});

            }).catch((err) => {

                this.hasProfileData = false;
                this.menuHidden = true;
                this.submitted = false;
                // console.log('API GET PROFILE BY ID ERROR', err);
            });

        }

    }

    address(cep): void {
        this.viacep.buscarPorCep(cep).then((endereco: Endereco) => {
            this.profileForm.patchValue(endereco);
        }).catch((error: ErroCep) => {
            this.profileForm.patchValue({
                logradouro: '',
                numero: '',
                bairro: '',
                localidade: '',
                uf: '',
                complemento: ''
            });
        });
    }

    get f() {
        return this.profileForm.controls;
    }

    onSubmit() {

        this.submitted = true;

        // console.log('this.profileForm', this.profileForm);

        if(this.updateUser && this.profileForm.controls.cpf.valid) {
            this.userService.update(this.userUpdate, this.profileForm.value).then((result: StatusMessage) => {
                this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
                // console.log('RESULT UPDATE', result);
            }).catch((error) => {
                // console.log('ERROR UPDATE', error);
                this.toastrService.error(error.message.erro.message, upperCaseFirstLetter(error.status));
            });

            return;
        } else {
            if(this.profileForm.invalid) {
                return;
            }

            this.userService.insert(this.profileForm.value).then((result: StatusMessage) => {
                this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
                // console.log('RESULT INSERT', result);
                this.router.navigate([`/profile/${result.user.id}`]);
            }).catch((error) => {
                this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
            });
        }

    }

    onReset() {
        this.submitted = false;
        this.profileForm.reset();
        // this.router.navigate([`/profile/${this.user.id}`]);
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

}
