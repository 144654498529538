import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-button-return',
    templateUrl: './button-return.component.html',
    styleUrls: ['./button-return.component.css']
})
export class ButtonReturnComponent implements OnInit {

    @Input() linkReturn: string = '/';
    constructor() {}

    ngOnInit() {}

}
