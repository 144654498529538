import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {isValidCpf, MustMatch, upperCaseFirstLetter} from '../../helpers';
import {StatusMessage} from '../../interfaces/status-message';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService, UserService} from '../../services';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

    passwordForm: FormGroup;
    submitted: boolean = false;
    token: string = '';

    constructor(private route: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private toastrService: ToastrService) {}

    ngOnInit() {

        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });

        if(this.route.snapshot.queryParams.hasOwnProperty('token')) {
            this.token = this.route.snapshot.queryParams.token;
        }
    }

    get f() {
        return this.passwordForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        if(this.passwordForm.invalid) {
            return;
        }

        let params = {password: this.passwordForm.controls.password.value, token: this.token};
        // console.log('PARAMS RECEIVE', params);
        this.authenticationService.validPassword(params).then((result) => {
            this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
            this.router.navigate([`/`]);
        }).catch((error) => {
            this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
        });

        /*this.userService.insert(this.passwordForm.value).then((result: StatusMessage) => {
            this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
            console.log('RESULT INSERT', result);
            this.router.navigate([`/profile/${result.user.id}`]);
        }).catch((error) => {
            this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
        });*/

    }

    onReset() {
        this.submitted = false;
        this.passwordForm.reset();
    }

}
