import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() idUser: number = 0;
  @Input() toggleMenu: boolean = false;
  constructor() { }

  ngOnInit() {}
}
