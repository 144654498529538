import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {OkaService, UserService} from '../../services';
import {Oka} from '../../interfaces/oka';
import {Endereco, ErroCep, NgxViacepService} from '@brunoc/ngx-viacep';
import {Address} from '../../interfaces/address';
import {StatusMessage} from '../../interfaces/status-message';
import {upperCaseFirstLetter} from '../../helpers';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'app-form-oka',
    templateUrl: './form-oka.component.html',
    styleUrls: ['./form-oka.component.css']
})
export class FormOkaComponent implements OnInit {

    navbarOpen = false;
    menuHidden: boolean = false;
    okaForm: FormGroup;
    id_user: number = 0;
    list_address: Address[] = [];
    hasOkaData: boolean = false;
    submitted: boolean = false;
    cepTemp: string = '';
    backLink: string = `/profile/`;
    oka: Oka;
    oldOka: Oka;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private okaService: OkaService,
                private userService: UserService,
                private viacep: NgxViacepService,
                private toastrService: ToastrService) {
    }

    async ngOnInit() {

        this.okaForm = this.formBuilder.group({
            name: ['', Validators.required],
            serial: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
            access_code: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            addressSelect: [''],
            cep: ['', Validators.required],
            logradouro: ['', Validators.required],
            numero: [''],
            bairro: ['', Validators.required],
            localidade: ['', Validators.required],
            complemento: [''],
            uf: ['', Validators.required],
            descricao: ['Principal']
        });

        this.id_user = this.route.snapshot.queryParams.id_user;
        this.backLink = `/profile/${this.id_user}`;
        this.getAddressByIdProfile(this.id_user);

        if(this.route.snapshot.params.hasOwnProperty('id')) {

            this.hasOkaData = true;
            const id_oka: number = typeof this.route.snapshot.params.id === 'string' ? parseInt(this.route.snapshot.params.id) : this.route.snapshot.params.id;

            await this.okaService.getOka(id_oka, true).then((oka: Oka) => {
                this.okaForm.patchValue(oka[0]);
                this.okaForm.controls.serial.disable();
                this.okaForm.controls.access_code.disable();
                this.okaForm.patchValue({addressSelect: oka[0].id_address});
                this.oldOka = oka[0];
            }).catch((error) => {
                // console.log('ERROR GET OKA', error);
            });
        }
    }

    get f() {
        return this.okaForm.controls;
    }

    address(cep): void {

        if (parseInt(cep.replace(/[^a-z0-9]/gi,'')) !== parseInt(this.cepTemp)) {
            this.okaForm.controls['addressSelect'].setValue('');
        }

        this.viacep.buscarPorCep(cep).then((endereco: Endereco) => {
            this.okaForm.patchValue(endereco);
        }).catch((error: ErroCep) => {
            this.okaForm.patchValue({
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                localidade: '',
                uf: '',
                complemento: ''
            });
        });
    }

    getAddressByIdProfile(id): void {
        this.userService.getAddressList(id).then((listAddress: Address[]) => {
            this.list_address = listAddress;
        }).catch((err) => {
            // console.log('ERROR GET ADDRESS BY ID PROFILE', err);
        });
    }

    onChange(data) {
        this.cepTemp = data.cep;
        this.okaForm.patchValue({addressSelect: data.id, ...data});
    }

    async onSubmit() {
        this.submitted = true;

        const {serial, access_code} = this.okaForm.controls;

        let isInvalidOka: boolean = false;
        await this.okaService.validSerialAndAccessCode(serial.value, access_code.value).then((result: boolean) => {
            isInvalidOka = result;
        }).catch((err: boolean) => {
            isInvalidOka = err;
            this.okaForm.controls['serial'].setErrors({invalid: true});
            this.okaForm.controls['access_code'].setErrors({invalid: true});
        });

        // console.log('OKA FORM', this.okaForm);
        if(this.okaForm.invalid) {
            return;
        }

        if (this.hasOkaData) {
            this.okaService.update(this.oldOka, {...this.okaForm.value, id_oka: this.route.snapshot.params.id}).then((result: StatusMessage) => {

                // console.log('RESULT', result);
                this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
                // this.router.navigate([`/profile/${this.id_user}`]);
            }).catch((error) => {
                this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
            });
        } else {
            this.okaService.insert({...this.okaForm.value, id_user: this.id_user}).then((result: StatusMessage) => {

                // console.log('RESULT', result);
                this.toastrService.success(result.message, upperCaseFirstLetter(result.status));
                this.router.navigate([`/profile/${this.id_user}`]);
            }).catch((error) => {
                this.toastrService.error(error.message, upperCaseFirstLetter(error.status));
            });
        }

    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }
}
