import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {

  @Input() data: any;
  @Input() col: number = 12;
  classCol: string = ``;
  constructor() { }

  ngOnInit() {
    this.classCol = `col-${this.col} col-sm-${this.col} col-md-${this.col} col-lg-${this.col} col-xl-${this.col}`;
  }

}
