import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnInit {

    @Input() placeholderInput: string = 'Placeholder';
    @Input() nameInput: string = 'name';
    @Input() typeInput: string = 'text';
    @Input() disabled: boolean = false;

    constructor() {}

    ngOnInit() {
        // console.log('name field', this.nameInput);
    }


    set isDisabled(value: boolean) {
        this.disabled = value;
    }
}
