import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

    id_user = null;
    returnLink: string = '/login';
    constructor(private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        // console.log('this.authenticationService', this.authenticationService);
        /*if (this.authenticationService.currentUserValue.hasOwnProperty('data')) {
            this.id_user = this.authenticationService.currentUserValue.data.id;
            this.returnLink = `/profile/${this.id_user}`;
        }*/
    }

}
