import {Component, OnInit} from '@angular/core';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface PromptModel {
    title: string;
    description: string;
    question: string;
    emails?: any[];
    actual_email?: string;
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent extends SimpleModalComponent<PromptModel, string> implements PromptModel, OnInit {

    title: string;
    description: string;
    question: string;
    message: string = '';
    emails: any[] = [];
    modalForm: FormGroup;


    constructor(private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.modalForm = this.formBuilder.group({
            emailSelected: [0],
            email: [{value: this.emails[0].email, disabled: true}, [Validators.required, Validators.email]],
        });

        this.emails.forEach((email, index) => {
            if(email.selected) {
                this.changeSelect(index);
            }
        });
    }

    changeSelect(value) {
        let id = value.hasOwnProperty('target') ? parseInt(value.target.value) : value;

        this.emails[id].email !== 'Outro' ? this.modalForm.controls['email'].disable() : this.modalForm.controls['email'].enable();
        this.modalForm.get('email').setValue(this.emails[id].email === 'Outro' ? '' : this.emails[id].email, {
            onlySelf: true
        });
    }

    apply() {
        this.result = this.modalForm.controls['email'].value;
        this.close();
    }
}
