import {AfterContentInit, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {Profile} from '../../interfaces/profile';
import {getAge} from '../../helpers';
import {Address} from '../../interfaces/address';
import {Oka} from '../../interfaces/oka';
import {HintService} from 'angular-custom-tour';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    providers: [HintService],
})
export class ProfileComponent implements OnInit, AfterContentInit {

    id_user: number = 0;
    user: Profile = {
        firstname: 'Nome',
        lastname: ' Sobrenome',
        age: 0,
        email: 'email'
    };
    okas: Oka[] = [];
    address: Address = {};
    navbarOpen = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                public hintService: HintService) {
    }

    ngOnInit() {
        this.id_user = this.route.snapshot.params.id;
    }

    async ngAfterContentInit() {
        await this.userService.getProfile(this.id_user).then((dataUser: Profile) => {
            // console.log('ID USER', this.id_user);
            // console.log('USER', dataUser);
            dataUser.age = getAge(dataUser.birth);
            this.user = dataUser;
            this.address = dataUser.address;
            this.okas = dataUser.okas;

        }).catch((err: any) => {
            // console.log('ERROR GET PROFILE', err);
        });

        if(this.user.firstname === 'Nome') {
            location.reload(true);
        }
    }

    getOkas(okasArray: []) {
        let result = [];

        okasArray.forEach((oka: {}) => {
            let serial = Object.keys(oka)[0];

            result.push(oka[serial]);
        });

        return result;
    }

    openOka(oka: any) {
        // console.log('OKA', oka);
        // this.router.navigate([`/oka/${oka.id_oka}`], { queryParams: {oka: JSON.stringify(oka), id_user: this.id_user}, skipLocationChange: true });
        this.router.navigate([`/oka/${oka.id}`], {
            queryParams: {
                user: JSON.stringify(this.user)
            }
        });
    }

    private getAddress(addressArray: Address[] | any) {

        return addressArray[0];

    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }


    startTour() {
        this.hintService.initialize({
            defaultLayer: 35,
            dismissOnOverlay: true
        });
    }
}


