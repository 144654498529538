import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';

import {Profile} from '../interfaces/profile';
import {environment} from '../../environments/environment';
import {StatusMessage} from '../interfaces/status-message';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    /*private currentUserSubject: BehaviorSubject<Profile>;
    public currentUser: Observable<Profile>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Profile>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    async login(userReceive: Profile) {

        // return await new Promise<StatusMessage>(async (resolve, reject) => {

            return this.http.post<any>(`${environment.API_URL}/auth/login`, userReceive).subscribe((user) => {

                // console.log('LOGIN SERVICE USER', user);
                this.setSession(user);
                // localStorage.setItem('currentUser', JSON.stringify(user));
                // localStorage.setItem('Authorization', user.data);
                // this.currentUserSubject.next(user.data);
                // resolve(user);

            }, error => {

                // console.log('reject(error)', error);
                // reject(error);

            });

        // });

    }

    private setSession(authResult) {
        const expiresAt = moment().add(authResult.expiresIn, 'second');

        // console.log('SET SESSION expiresAt ', expiresAt);
        // console.log('SET SESSION authResult ', authResult);

        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        // localStorage.removeItem('currentUser');
        // this.currentUserSubject.next(null);
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }*/
    constructor(private http: HttpClient, private route: ActivatedRoute) {
    }

    login(userReceive: Profile) {
        return this.http.post<any>(`${environment.API_URL}/auth/login`, userReceive)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                // console.log('USER', user);
                if(user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('Authorization', user.token);
                    localStorage.setItem('user_id', user.id);
                }

                return {status: user.status, message: user.message, id: user.hasOwnProperty('id') ? user.id : null};
            }));
    }

    async validPassword(params: { password: string, token: string }) {

        return await new Promise<StatusMessage>(async (resolve, reject) => {

            await this.http.post(`${environment.API_URL}/auth/refresh-password`, params).subscribe(
                (result: StatusMessage) => {
                    resolve(result);
                },
                (error: StatusMessage) => reject(error));

        });

    }

    public isLoggedIn() {
        // console.log('snapshot', this.route.snapshot.params['id']);
        // console.log('localstorage', localStorage.getItem('user_id'));

        if (parseInt(localStorage.getItem('user_id')) === parseInt(this.route.snapshot.params['id'])) {
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user_id');
        localStorage.removeItem('Authorization');
    }

}
