import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StatusMessage} from '../interfaces/status-message';
import {Address} from '../interfaces/address';
import {Profile} from '../interfaces/profile';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KokarService {

  constructor(private http: HttpClient) {}

  async sendEmail(params: {email: string}): Promise<StatusMessage> {

    return new Promise<StatusMessage>(async (resolve, reject) => {

      await this.http.post(`${environment.API_URL}/auth/send-email`, params).subscribe(
          (resultData: StatusMessage) => resolve(resultData),
          (error: any) => reject({status: 'Erro', message: error}));
    });
  }
}
